<template>
  <section id="sub">
    <div class="sub-vis main-banner"><span>PARTNERS</span></div>
    
    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/company/partners">전시장안내</router-link>
    </div>

    

    <div id="content">
    
        <div id="sb-search">
            <fieldset>
                <div class="where">
                    <select name="sel_search" id="sel_search" v-model="option.company">
                        <option value="">업체전체</option>
                        <option :value="item.no" v-for="item in company" :key="item.no">{{item.company}}</option>
                    </select>
                </div>
                <div class="where">
                    <select name="sel_search" id="sel_search" v-model="option.area">
                        <option value="">지역전체</option>
                        <option :value="item.no" v-for="item in area" :key="item.no">{{item.area}}</option>
                    </select>
                </div>
                <div class="search_inp inp">
                    <div>
                        <input type="text" class="keyword" placeholder="검색어를 입력해주세요." v-model="option.search" maxlength="30">
                    </div>
                </div>
            </fieldset>
        </div>

        <div class="prdt-list2 mt0 w-100" style="padding-left: 0;">
            <ul class="list">
                <li v-for="(item,index) in postList" :key="index">
                    <router-link :to="`/company/partners/${item.no}`" class="link"></router-link>
                    <div class="tmb" :style="`background-image: url(${AWSURL + item.img})`"></div>
                    <strong>{{item.name}}</strong>
                </li>
            </ul>
        </div>
    </div>
  </section>
</template>

<script>
export default {
    data(){
        return{
            AWSURL: this.$store.state.AWS.URL,
            partner: [],

            option: {
                company: '',
                area: '',
                search: ''
            },

            company: [],
            area: []
        }
    },
    mounted() {
        this.get_partner();
        this.list_area();
        this.list_company();
    },
    computed: {
        postList() {
            return this.partner.filter(post => {
                return (
                    post.company_no
                    .toLowerCase()
                    .includes(this.option.company.toString().toLowerCase()) &&
                    post.area_no
                    .toLowerCase()
                    .includes(this.option.area.toString().toLowerCase()) &&
                    post.name
                    .toLowerCase()
                    .includes(this.option.search.toLowerCase())
                )
            })
        },
    },
    methods:{
        get_partner() {
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/partner/list`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.partner = res.data.info;
                    }
                }
            )
        },
        list_area() {            
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/partner/area`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.area = res.data.list;
                    }
                }
            )
        },
        list_company() {       
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/partner/company`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.company = res.data.list;
                    }
                }
            )
        }
    },
};
</script>

<style scope>
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}
</style>

<style scoped>
#sub #content {
    padding: 15px 0;
}

.search_st{
    font-variant: all-petite-caps;
    font-size: 26px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 38px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #50649c;
}

.order_search .search_st{
    min-width: 120px;
}

.user_form_group {
    position: relative;
    width: 1420px;
    margin: 0 auto;
    text-align: right;
}

#sb-search .search_inp {
    display: block;
    width:100%
}

@media screen and (max-width: 750px)
{
    #sb-search .inp {
        padding-right: 35px;
        width: 85%;
    }
}

</style>