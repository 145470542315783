<template>
  <section id="sub">
    <div class="sub-vis main-banner"><span>PORTFOLIO</span></div>
    
    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/portfolio/khanstone">시공사례</router-link> > 
        <span>{{ open_category }}</span>
    </div>
    
    <!-- <div class="sub-tit">
      <h3>시공사례</h3>
      <span></span>
    </div> -->
    <!-- navigator -->
    <div id="content">
        <div id="sb-search">
            <fieldset>
                <legend>게시판 검색</legend>
                <div class="search_inp inp">
                    <div>
                        <input type="text" class="keyword" placeholder="검색어를 입력해주세요." v-model="option.search" maxlength="30">
                    </div>
                </div>
            </fieldset>
        </div>
        
        <div class="lnbWrap">
            <ul id="lnb">
                <li v-for="item in category" :key="item.no" :class="{ 'active': open == item.no }">
                    <a href="javascript:void(0)" @click="open = item.no; open_category = item.e_category; option.product_name = ''; get_product(); get_portfolio()">{{item.category}}</a>
        
                    <ul class="depth02" v-show="open===item.no">
                        <li v-for="p_item in product" :key="p_item.no">
                            <a href="javascript:;" @click="data_filter(p_item.product_name)">{{p_item.product_name}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <!-- <div class="lnbWrap">
            <ul id="lnb">
                <li v-for="item in category" :key="item.no"  :class="{'active':$route.params.idx==item.e_category}">
                    <a href="javascript:void(0)" @click="open=item.no">{{item.category}}</a>
                    <ul class="depth02" v-show="open===item.no">
                        <li @click="get_portfolio(0)"><router-link :to="`/portfolio/${item.e_category}`">전체</router-link></li>
                        <li v-for="p_item in product" :key="p_item.no" @click="get_portfolio(p_item.no)"><router-link :to="`/portfolio/${item.e_category}`">{{p_item.product_name}}</router-link></li>
                    </ul>
                </li>
            </ul>
        </div> -->

        <div class="prdt-list2 mt0">
            <ul class="list">
                <li v-for="(item,index) in postList" :key="index">
                    <router-link :to="`/portfolio/${item.e_category}/${item.no}`" class="link"></router-link>
                    <div class="tmb" :style="`background-image: url(${AWSURL + 'portfolio/' + item.thumbnail})`"></div>
                    <strong>{{item.product_name}} ({{item.location}})</strong>
                </li>
            </ul>
        </div>
    </div>
  </section>
</template>

<script>
export default {
    watch: {
        $route(to, from) {
            if (to.path != from.path) {
                this.get_portfolio()
                this.get_product();
            }
        } 
    },
    data(){
        return {
            open: 1,
            open_category: 'khanstone',
            AWSURL: this.$store.state.AWS.URL,
            select_color: 0,
            color: [],
            category: [],
            product: [],
            select_color_no: ['0'],
            select: [],
            option: {
                search: '',
                product_name: ''
            },

            list: [],
        }
    },
    created() {
        this.get_product();
    },
    mounted() {
        this.get_category();
        this.get_portfolio();
    },
    computed: {
        postList() {
            return this.list.filter(post => {
                return (
                    post.product_name
                        .toLowerCase()
                        .includes(this.option.product_name.toLowerCase())&&
                    post.product_name
                        .toLowerCase()
                        .includes(this.option.search.toLowerCase())
                )
            })
        },
    },
    methods:{
        get_category() {
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/menu/category`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.category = res.data.info;
                    }
                }
            )
        },
        get_portfolio() {            
            const post = this.open_category;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/portfolio/list`, {post}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.list = res.data.info;
                    }
                }
            )
        },
        get_product() {
            const color_num = this.select_color_no.join(',');
            const post = this.open_category;
            const api = this.$store.state.api;
            this.$http.post(`${api}/member/product/list`, { post, color_num }
            ).then(
                res => {
                    if (res.status == 200) {
                        this.product = res.data.info;
                    }
                }
            )
        },
        data_filter(product_name) {
            this.option.product_name = product_name;
        }
    },
};
</script>

<style scope>
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}

#sb-search {
    padding-bottom: 20px;
    border-bottom: 0px;
}

#sb-search .search_inp {
    display: block;
    width:100%
}

.lnbWrap .depth02>li{        
    font-size: 14px;
    margin: 4px;
}
    
@media screen and (max-width: 750px)
{
    #sb-search .inp {
        padding-right: 35px;
        width: 85%;
    }
}

</style>