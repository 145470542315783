<template>
  <section id="sub">
    <div class="sub-vis main-banner"><span>GUIDE</span></div>
    
    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/company/guide">구매가이드</router-link>
    </div>
    
    <div id="content">
        <div id="aboutus" class="w-100">
            <div class="checker">
                <dl v-for="(item, index) in guide" :key="index">
                    <dt :style="`background-image: url(${AWSURL + item.img});`">
                    </dt>
                    <dd>
                        <strong>{{item.title}}</strong><br class="__pc">
                        <pre>{{item.content}}</pre>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    
    data() {
        return {
            AWSURL: this.$store.state.AWS.URL,
            guide: []
        }
    },
    mounted() {
        this.get_guide();
    },
    methods: {        
        get_guide(){
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/guide/list`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.guide = res.data.info;
                    }
                }
            )            
        }
    },
};
</script>

<style scoped>
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}

.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}
#aboutus .checker>dl>dt {
    position: absolute;
    top: 0;
}
#aboutus .checker>dl:nth-child(odd)>dd {
    margin-left: 50%;
    padding-left: 110px;
}

#aboutus .checker>dl:nth-child(even)>dd {
    margin-right: 50%;
    padding-left: 195px;
    padding-left: 50px;
}
@media screen and (max-width: 768px) {
    #aboutus .checker>dl:nth-child(odd)>dd {
        margin-left: 0;
        padding-left: 0;
    }

    #aboutus .checker>dl:nth-child(even)>dd {
        margin-right: 0;
        padding-left: 0;
    }
}
@media screen and (max-width: 1200px) {
    #aboutus .checker>dl>dt {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 50%;
    }
    #aboutus .checker>dl>dd {
        margin: 30px 0;
    }

    #aboutus .checker>dl:nth-child(even)>dd{
        margin-right: 0;
        margin-left: 0;
    }
    #aboutus .checker>dl:nth-child(odd)>dd{
        margin-right: 0;
        margin-left: 0;
    }
}

pre{
    white-space: pre-line;
}
</style>