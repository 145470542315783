<template>
<section id="sub">
    <div class="sub-vis main-banner"><span>PRODUCT</span></div>
        
    <div class="container breadcrumbs">
        <router-link to="/"><i class="fal fa-home"></i></router-link> > 
        <router-link to="/product/khanstone">제품소개</router-link> > 
        <span>{{ open_category }}</span>
    </div>
    
        
    <div class="select">
        <dl class="color">				
            <ul>				
                <li v-for="item in color" :key="item.no" @click="select_color = item.no">
                    <label>
                        <input type="checkbox" name="colors" :value="item.color_num" v-model="select_color_no" @change="color_chk(item.color_num)">
                        <em :style="`background-color: ${item.color}`">{{allCustom(item.color_num)}}</em>
                    </label>
                </li>
            </ul>
        </dl>
    </div>

        <!-- start content -->
    <div id="content" style="padding-top:0px">
        <div id="sb-search">
            <fieldset>
                <legend>게시판 검색</legend>
                <div class="search_inp inp">
                    <div>
                        <input type="text" class="keyword" placeholder="검색어를 입력해주세요." v-model="option.search" maxlength="30">
                    </div>
                    <!-- <div class="search_btn">
                        <a class="sbm"/>
                        <i class="far fa-search"></i>
                    </div> -->
                </div>
            </fieldset>
        </div>


        <!-- <div class="lnbWrap">
            <ul id="lnb">
                <li v-for="item in category" :key="item.no"  :class="{'active':$route.params.idx==item.e_category}" @click="get_product()">
                    <router-link :to="`/product/${item.e_category}`">{{item.category}}</router-link>
                </li>
            </ul>
        </div> -->
        
        <div class="lnbWrap">
            <ul id="lnb">
                <li v-for="item in category" :key="item.no" :class="{ 'active': open == item.no }">
                    <a href="javascript:void(0)" @click="open = item.no; open_category = item.e_category; get_product()">{{item.category}}</a>

                    <ul class="depth02" v-show="open===item.no">
                        <li v-for="p_item in product" :key="p_item.no">
                            <router-link :to="`/product/${item.e_category}/${p_item.no}`">{{p_item.product_name}}</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="prdt-list2">
            <ul class="list">
                <li v-for="item in postList" :key="item.no">
                    <router-link :to="`/product/${item.e_category}/${item.no}`">
                        <div class="tmb" :style="`background-image: url(${AWSURL + item.product_img})`"></div>
                        <strong>{{item.product_name}}</strong>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</section>
</template>

<script>
export default {
    watch: {
        $route(to, from) {
            if (to.path != from.path) {
                this.get_product()
            }
        } 
    },
    data(){
        return{
            open: 1,
            open_category: 'khanstone',
            AWSURL: this.$store.state.AWS.URL,
            select_color: 0,
            color: [],
            category: [],
            product:[],
            select_color_no: ['0'],
            select:[],
            
            option: {
                search: ''
            }
        }
    },
    created() {
        this.get_product();
    },
    mounted() {
        this.get_category();
        this.get_color();
    },
    computed: {
        postList() {
            return this.product.filter(post => {
                return (
                    post.product_name
                    .toLowerCase()
                    .includes(this.option.search.toLowerCase())
                )
            })
        },
    },
    methods:{        
        color_chk(color){
            if(color == '0'){
                this.select_color_no = [];
                this.select_color_no.push('0');
            }
            else {
                for(var i=0; i<this.select_color_no.length; i++){
                    if(this.select_color_no[i] == 0){
                        this.select_color_no.splice(i, 1)
                    }
                }
            }
            this.get_product();
        },
        get_color() {
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/color`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.color = res.data.info;
                    }
                }
            )
        },
        get_category() {
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/home/menu/category`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.category = res.data.info;
                    }
                }
            )
        },
        get_product() {
            const color_num = this.select_color_no.join(',');
            const post = this.open_category;
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/product/list`, {post, color_num}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.product = res.data.info;
                    }
                }
            )
        },
        allCustom(num){
            if(num == 0) return 'ALL'
            else return ''
        }
    },
};
</script>

<style scope>
.main-banner{
    background-image: url('../../assets/images/banner/sub_img.png');
}

.select {
    border: 1px solid #d1d1d1;
    padding: 15px 17px;
    /* margin: 10px 0px; */
    color: #2b2b2b;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    margin-top: 35px;
    max-width: 100%;
    overflow-x: auto;
    box-sizing: border-box;
}

.select dl {
    position: relative;
    min-height: 32px;
    margin-bottom: 1px;
    margin-bottom: 1px;
}

.select dl dt {
    padding: 0px 0px;
    width: 125px;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #f3f4f6;
    text-align: center;
}

.select dl dd {
    padding-left: 150px;
}

.select dl dd ul {
    overflow: hidden;
}

.select dl.color>ul{
    display: flex;
}
.select dl.color li {
    margin-bottom: 0px;
    margin-top: 2px;
    display: inline-block;
}

.select dl label {
    cursor: pointer;
    /* padding: 11px; */
    letter-spacing: -1px;
}

.select dl.color input[type="checkbox"] {
    display: none;
}

.select dl dd li input {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.select dl.color input[type="checkbox"] + em {
    width: 30px;
    height: 30px;
    border: 1px solid #e4e4e4;
    /* display: inline-block; */
    
    display: flex;
    align-items: center;
    margin: 11px;
    justify-content: center;
    border-radius: 3px;
}

.select dl.color input[type="checkbox"] + em.all {
    font-style: inherit;
    font-weight: 800;
}

.select dl.color input[type="checkbox"]:checked + em {
    border: 1px solid #ff8000;
    box-shadow: 0 0 0 1px #fff inset;
    border-radius: 3px;
}

.select dl.color input[type="checkbox"]:checked + em.all {
    border: 1px solid #ff8000;
    box-shadow: 0 0 0 1px #fff inset;
    border-radius: 3px;
}
.main-banner>span {
    position: absolute;
    font-weight: bolder;
    color: white;
    font-size: -webkit-xxx-large;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.prdt-list2 {
    margin-top: 0px;
}

#sb-search .search_inp {
    display: block;
    width:100%
}

@media screen and (max-width: 750px)
{
    #sb-search .inp {
        padding-right: 35px;
        width: 85%;
    }
}
</style>