<template>
<section id="main">
    <div class="bg">
        <video muted autoplay loop v-if="setVideo != {}" ref="video" style="background-color: black;">
            <source :src="setVideo.img" type="video/mp4">
        </video>
        <div class="text">
            <p><img style="width: 30%;" src="../../assets/icons/ikan_white.png"></p>
        </div>
        <!-- <div class="text" style="top:95%">
            <div class="maintxt1 skrollable skrollable-between" data-0="bottom:0; opacity:1;" data-1000="bottom: 0; opacity:0;" style="bottom: 0; opacity: 1;">
                <h3>공간의 가치를 높이는 선택</h3>
                <p>
                    천연소재에 첨단기술을 더한 친환경 신소재로서<br>
                    광택 및 표면강도, 내오염성이 우수할 뿐만 아니라<br>
                    기존 석재 및 인조석과 비교할 때 월등한 품질과<br>
                    아름다움을 가집니다.<br>
                </p>
            </div>
        </div> -->
            
        <div class="swiper-button-next nextBtn2 skrollable skrollable-between" data-0="bottom:0; opacity:1;" data-1000="bottom: 0; opacity:0;" tabindex="0" role="button" aria-label="Next slide" style="bottom: 0; opacity: 1;">
            <a href="javascript:;" @click="nextVideo">
                <img src="../../assets/icons/next_arrow.png" alt="">
            </a>
        </div>
        <div class="swiper-button-prev nextBtn2 skrollable skrollable-between" data-0="bottom:0; opacity:1;" data-1000="bottom: 0; opacity:0;" tabindex="0" role="button" aria-label="Previous slide" style="bottom: 0; opacity: 1;">
            <a href="javascript:;" @click="predVideo">
                <img src="../../assets/icons/prev_arrow.png" alt="">
            </a>            
        </div>
    </div>

    
    <!-- <VueSlickCarousel v-bind="slickOptions" v-if="slide.length >0" class="custom-slick">
        <div v-for="(item, index) in slide" :key="index">
            <img class="main_icon" :src="AWSURL + item.img" alt="메인 아이콘">
        </div>        
    </VueSlickCarousel > -->
    
    <div id="content">

        
        <div class="portfolio-wrap">
            <hgroup>
                <h3 style="text-align:left;">인기상품</h3>
                <h4></h4>
            </hgroup>

            <div>
            </div>
            <div class="d-flex">
                <ul class="roll">
                    <li v-for="(item,index) in popular" :key="index">
                        <router-link :to="`/product/${item.e_category}/${item.no}`" class="link">
                            <div class="bg_img bg-main" :style="`background-image: url(${AWSURL + item.product_img})`"><span class="bg-cover" v-if="item.popular != '0'">BEST상품</span></div>
                            <strong class="mb-30">{{item.product_name}} ({{item.product_no}})</strong>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="portfolio-wrap">
            <hgroup>
                <h3 style="text-align:left;">시공사례</h3>
                <h4></h4>
            </hgroup>

            <ul class="prtf-list"> 
                <li v-for="(item,index) in prtf_list" :key="index">
                    <router-link :to="`/portfolio/${item.e_category}/${item.no}`" class="link"></router-link>
                    <div class="tmb" :style="`background-image: url(${AWSURL+'portfolio/'+item.thumbnail})`"></div>
                    <strong>{{item.product_name}} ({{item.location}})</strong>
                </li>

            </ul>

            <!-- <router-link to="/" class="more">더보기</router-link> -->
        </div>

        

        
        
        <div class="portfolio-wrap">
            <hgroup>
                <h3 style="text-align:left;">전시장안내</h3>
                <h4></h4>
            </hgroup>

            <div class="d-flex">
                <ul class="roll">
                    <li v-for="(item,index) in partner" :key="index">
                        <router-link :to="`/company/partners/${item.no}`" class="link">
                            <div class="bg_img" :style="`background-image: url(${AWSURL + item.img})`"><span class="bg-cover" v-if="item.new == 'Y'">신규매장</span></div>
                            <strong class="mb-30">{{item.name}}</strong>
                        </router-link>
                        <!-- <span>more +</span> -->
                    </li>
                </ul>
            </div>
        </div>
        <!-- <Popup></Popup> -->
    </div>
</section>
</template>
<script>
// import VueSlickCarousel from 'vue-slick-carousel'

// import Popup from '@/components/common/Popup.vue'
export default {
    // components: { Popup },
    data() {
        return {            
            AWSURL: this.$store.state.AWS.URL,
            contractTF:false,
            slickOptions: {
                infinite: true,
				slidesToShow: 1,
				slidesToScroll:1,
                fade: true,
                speed: 500,
				arrows:false,
                dots: true,
                autoplay : true,			// 자동 스크롤 사용 여부
				pauseOnHover : true,		// 슬라이드 이동	시 마우스 호버하면 슬라이더 멈추게 설정
				vertical : false,		// 세로 방향 슬라이드 옵션
				prevArrow : "<button type='button' class='slick-prev'>Previous</button>",		// 이전 화살표 모양 설정
				nextArrow : "<button type='button' class='slick-next'>Next</button>",		// 다음 화살표 모양 설정
				dotsClass : "slick-dots", 	//아래 나오는 페이지네이션(점) css class 지정
				draggable : true, 	//드래그 가능 여부 
				
				responsive: [ // 반응형 웹 구현 옵션
					{  
						breakpoint: 960, //화면 사이즈 960px
						settings: {
							//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
							slidesToShow:3 
						} 
					},
					{ 
						breakpoint: 768, //화면 사이즈 768px
						settings: {	
							//위에 옵션이 디폴트 , 여기에 추가하면 그걸로 변경
							slidesToShow:2 
						} 
					}
				]
            },
            slide: [
                {no: 1, img: '/assets/video/Patagonia_t.mp4'},                
                {no: 2, img: '/assets/video/CANAL_video.mp4'}
            ],
            setVideo: {no: 1, img: '/assets/video/Patagonia_t.mp4'},
            popular: [],
            prtf_list: [],
            partner: []
        }
    },
    mounted(){
        var scrollInfo = document.querySelectorAll(".roll");

        for( let i=0; i<scrollInfo.length; i++ ){
            scrollInfo[i].addEventListener('wheel', (evt) => {
                evt.preventDefault();
                scrollInfo[i].scrollLeft += evt.deltaY;
            });
        }

        // this.get_slideIMG();
        this.popularProduct();
        this.get_portfolio();
        this.get_partner();
    },
    methods: {
        get_slideIMG() {            
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/main/slide/image`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.slide = res.data.info;
                    }
                }
            )
        },
        popularProduct() {            
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/main/popular/product`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.popular = res.data.info;
                    }
                }
            )
        },
        get_portfolio(){         
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/main/portfolio`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        this.prtf_list = res.data.info;
                    }
                }
            )            
        },
        get_partner(){
            const api = this.$store.state.api;            
            this.$http.post(`${api}/member/main/partner`, {}
            ).then(
                res => {
                    if(res.status == 200) {
                        console.log(res.data)
                        this.partner = res.data.info;
                    }
                }
            )            
        },
        nextVideo() {
            if(this.setVideo.no == 1){
                this.setVideo = this.slide[1];
            }
            else {
                this.setVideo = this.slide[0];
            }
            var vid = this.$refs.video;
            vid.load();
        },
        predVideo() {
            if(this.setVideo.no == 1){
                this.setVideo = this.slide[1];
            }
            else {
                this.setVideo = this.slide[0];
            }
            var vid = this.$refs.video;
            vid.load();
        }
    },
}
</script>

<style scoped>
#main{
    text-align: center;
}
    .portfolio-wrap .d-flex{
        display: flex;
        flex-wrap: nowrap;
    }
    .portfolio-wrap .side{
        flex-shrink: 0;
        width: 358px;
        /* padding-top: 50px; */
        text-align: left;
    }

    .portfolio-wrap .side strong{
        display: block;
        margin-bottom: 50px;
        font-family: Noto Sans KR;
        font-weight: 500;
        font-size: 40px;
        color: #000000;
        line-height: 1em;
        text-transform: uppercase;
        
    }
    .portfolio-wrap .side strong::after{
        content: '';
        margin-top: 20px;
        display: block;
        width: 60px;
        height: 1px;
        background: #000;
        
    }
    .portfolio-wrap .side a{
        padding: 10px 20px;
        /* border: 2px solid #000;
        color: #000; */
        border: 2px solid #47a6b9;
        color: #47a6b9;
        text-decoration: none;
    }
    .portfolio-wrap .roll{
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        margin: 0 -10px;
        white-space: nowrap;
        margin-bottom: 30px;
    }
    .portfolio-wrap .roll li{
        width: 340px;
        flex-shrink: 0;
        display: inline-block;
        vertical-align: top;
        margin: 0 10px;
        position: relative;
        text-align: left;
        cursor: pointer;
    }
    .portfolio-wrap .roll li strong {
        display: block;
        margin-top: 15px;
        font-size: 20px;
        color: #000000;
        font-weight: 600;
        letter-spacing: -0.02em;
        line-height: 20px;
    }
    .portfolio-wrap .roll li span {
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: Noto Sans KR;
        font-weight: 500;
        font-size: 14px;
        color: #999999;
        line-height: 1em;
    }
    .portfolio-wrap .bg_img{
        width: 100%;
        height: 255px;
    }

    .banner-wrap{
        position: relative;
        max-width: 1420px;
        margin: 0 auto;
        width: 100%;
    }
    .banner-wrap::before{
        content: "";
        position: absolute;
        top: 20px;
        left: 20px;
        width: calc(100% - 40px);
        height: calc(100% - 40px);
        box-sizing: border-box;
        z-index: 1;
        display: block;
        border: 1px solid #fff;
    }
    .banner-wrap::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background-color: rgba(0, 0, 0, 0.4);
    }
    .banner-wrap a{
        width: 100%;
        height: 100%;
        display: block;
        padding: 100px 0;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        z-index: 8;
    }
    .banner-wrap .txt_box{
        position: relative;
        z-index: 9;
    }
    .banner-wrap .txt_box>*{
        text-align: center;
        color: #fff;
        text-decoration: none;
        font-size: 20px;
        font-weight: lighter;
    }
    .banner-wrap .txt_box>p{
        margin-top: 10px;
    }
    .banner-wrap .txt_box>strong{
        font-size: 40px;
        font-weight: lighter;
        margin-bottom: 20px;
    }
    .banner-wrap .txt_box>.more{
        font-size: 40px;
        font-size: 1em;
        font-weight: lighter;
    }

    .cuntacts .wrap{
        max-width: 1420px;
        margin: 0 auto;
        display: flex;
    }
    .cuntacts .wrap .banner-wrap:first-child{
        margin-right: 20px;
    }

    .tab-tab{
        text-transform: uppercase
    }

    .more{
        /* background-color: #000; */
        background-color: #47a6b9;
        padding: 16px 30px;
        font-size: 1em;
        display: inline-block;
        margin-top: 50px;
        color: #fff;
    }

    
    @media screen and (max-width: 1200px){
        .portfolio-wrap .side strong {
            margin-bottom: 20px;
            font-size: 20px;
        }
        .portfolio-wrap .side a{
            padding: 6px 14px;
            font-size: 12px;
        }
        .portfolio-wrap .d-flex{
            flex-wrap: wrap;
        }
        .portfolio-wrap .roll{
            margin-top: 40px;
        }
        .portfolio-wrap .roll li{
            width: 280px;
        }
    }

    @media screen and (max-width: 1200px) {
        .cuntacts .wrap{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
        }
        .cuntacts .wrap .banner-wrap:first-child{
            margin-right: 0;
        }

    }
</style>

<style>
.custom-slick{
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 1000px;
}
.slick-dots{
    display: flex !important;
    position: absolute;
    bottom: 50px;
    width: 100%;
    justify-content: center;
}
.slick-dots li {
    display: inline-block;
    margin: 0 5px;
}
.slick-dots li button {
    display: block;
    width: 11px;
    height: 11px;
    background: #f1f1f1;
    text-indent: -999em;
    overflow: hidden;
    border: none;
    border-radius: 6px;
}
.slick-dots li.slick-active button {
    width: 30px;
    background: #1f98b1;
}
.slick-slide img{
    object-fit: cover;
    width: 100%;
    object-position: center center;
    touch-action: none;
}







/**
 *  Progress Bar
 */
 
/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.progress {
  display: block;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
  
  background-color: #f5f5f5;
  background-image: linear-gradient(to right, black, black);
  background-repeat: no-repeat;
  background-size: 0 100%;
  
  transition: background-size .4s ease-in-out;
}

.more-btn {
    padding: 10px 20px;
    border: 2px solid #47a6b9;
    color: #47a6b9;
    text-decoration: none;
    display: inline-block;
}

.main-img {
    text-align: left;
    font-size: 35px;
    font-weight: 600;
    position: absolute;
}

.main-title {
    display: flex !important;
    position: absolute !important;
    font-size: 35px !important;
    font-weight: 600 !important;
}

.bg-cover {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    background: #47a6b9;
    margin-top: 0px !important;
    color: #fff !important;
    font-weight: 600 !important;
}

.mb-30 {
    margin-bottom: 30px;
}

.bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0px auto;
  position: relative;
}
video {
  width: 100%;
}
.text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.text p {
  /* text-align: center; */
  /* font-size: 48px; */
  color: #ffffff;
}

.swiper-button-next {
    top: auto;
    right: 40px;
}

.nextBtn2 {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid #fff;
    bottom: 236px;
    touch-action: auto;
    pointer-events: auto;
    position: absolute;
    margin: 5%;
}

.nextBtn2 img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.swiper-button-nex, .swiper-button-prevt {
    left: auto;
    position: absolute;
    margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
}

.swiper-button-prev {
    top: auto;
    left: auto;
    right: 96px;
}

.swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
    content: '';
}

.maintxt1 {
    position: absolute;
    left: 40px;
    font-size: 14px;
}

.maintxt1 h3 {
    margin-bottom: 13px;
    color: #ffffff !important;
}

@media screen and (max-width: 1200px) {
    .swiper-button-next {
        position: absolute;
        top: 75%;
        left: 89%;
        transform: translate(-50%,-50%);
        width: 30px;
        height: 30px;
    }    

    .swiper-button-prev {
        position: absolute;
        top: 75%;
        left: 80%;
        transform: translate(-50%,-50%);
        width: 30px;
        height: 30px;
    }
}


</style>